import { ActivityIndicator } from '@troon/ui';
import { createEffect, Show, Suspense } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { cachedGet } from '@troon/api-client';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function ConfirmVisaRewards(props: RouteSectionProps) {
	const result = createAsync(() => verifyToken({ path: { token: Object.keys(props.location.query)[0] ?? '' } }), {
		deferStream: true,
	});

	createEffect(() => {
		if (result()) {
			window.parent?.postMessage(JSON.stringify(result()));
		}
	});

	// NOTE: this always shows the  activity indicator and relies on the parent window to handle the
	// success or failure message and state.
	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={result()}>
				<ActivityIndicator />
			</Show>
		</Suspense>
	);
}

export const route = { info: { nav: { appearance: 'none' }, banner: { hide: true } } } satisfies RouteDefinition;

const verifyToken = cachedGet('/visa/verify/{token}', {});
