import { Title } from '@solidjs/meta';
import { Button, Container, Heading, Link, Page, Section } from '@troon/ui';
import { getConfigValue } from '../../../modules/config';
import { Hero } from '../../../components/hero/photo';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import type { RouteDefinition } from '@solidjs/router';

export default function VisaRewards() {
	return (
		<>
			<Title>Visa | Troon Rewards | Troon</Title>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/member-programs.jpg`}>
				<Heading as="h1">Visa Golf Benefits</Heading>
				<p>by Troon Rewards®</p>
			</Hero>

			<Container>
				<Page>
					<Grid>
						<GridSeven>
							<Section>
								<Heading as="h2">Enjoy Premium Benefits</Heading>
								<p>
									Qualifying Visa cards entitle you to automatic upgraded status with Troon Rewards®, savings on tee
									time fees, merchandise, and lessons at over 150 golf courses across the United States and world.
								</p>
								<p>
									Visa Infinite card also gives access to over 20 high-end, private Troon Privé golf clubs. Play each
									club two times per year for $99 per player per round when you use your Visa Infinite card to book, and
									bring up to three guests at the same rate.
								</p>
							</Section>
						</GridSeven>
						<GridFive>
							<Section appearance="contained" class="bg-neutral-100">
								<Heading as="h2" size="h4">
									Redeem Your Visa Benefits
								</Heading>

								<p>Select your qualifying Visa card to redeem your benefits now.</p>

								<Heading as="h3" size="h5">
									US Cardholders
								</Heading>

								<Button as={Link} href="/rewards/visa/verify/visa-signature" appearance="tertiary">
									Visa Signature®
								</Button>
								<Button as={Link} href="/rewards/visa/verify/visa-infinite" appearance="tertiary">
									Visa Infinite®
								</Button>

								<Heading as="h3" size="h5">
									Canadian Cardholders
								</Heading>
								<Button as={Link} href="/rewards/visa/verify/visa-infinite-ca" appearance="tertiary">
									Visa Infinite®
								</Button>
								<Button as={Link} href="/rewards/visa/verify/visa-infinite-privilege-ca" appearance="tertiary">
									Visa Infinite Privilege®
								</Button>
							</Section>
						</GridFive>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
