import { ActivityIndicator, Container, Heading, MessageBar, Page, Section, SectionStep, TextLink } from '@troon/ui';
import { createEffect, ErrorBoundary, onCleanup, onMount, Show, Suspense } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { useAction, useSubmission } from '@solidjs/router';
import { clientAction } from '@troon/api-client';
import { createStore } from 'solid-js/store';
import { isServer } from 'solid-js/web';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { useUser } from '../../../providers/user';
import { AuthFlow } from '../../../partials/auth/auth';
import { logout } from '../../auth/components/logout-action';
import { authHeaders } from '../../access/checkout/[id]';
import { getConfigValue } from '../../../modules/config';
import type { ApiResponse } from '@troon/api-client';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function VerifyVisaRewards(props: RouteSectionProps) {
	const user = useUser();
	const trackEvent = useTrackEvent();
	// eslint-disable-next-line solid/reactivity
	const handleLogout = logout(trackEvent, `/rewards/visa/verify${props.params.cardType ?? ''}`);

	const getVisaIframeToken = useAction(postGetVisaIframeToken.with(new FormData()));
	const submission = useSubmission(postGetVisaIframeToken);
	const [verification, setVerification] = createStore<Partial<ApiResponse<'get', '/visa/verify/{token}'>>>({});

	createEffect(() => {
		if (user()?.me && !submission.result?.token && !submission.pending) {
			getVisaIframeToken();
		}
	});

	function listener(e: MessageEvent) {
		if (e.origin === `${window.location.protocol}//${window.location.host}`) {
			try {
				const response = JSON.parse(e.data) as ApiResponse<'get', '/visa/verify/{token}'>;
				setVerification(response);
			} catch {
				// no-op
			}
		}
	}

	onMount(() => {
		if (!isServer) {
			window.addEventListener('message', listener);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			window.removeEventListener('message', listener);
		}
	});

	return (
		<div class="h-full grow bg-neutral-100">
			<Container>
				<Page>
					<Grid>
						<GridSeven class="flex flex-col gap-8">
							<Heading as="h1">Verify your Visa Benefits</Heading>
							<Section appearance="contained">
								<Show
									when={user()}
									fallback={
										<div class="flex flex-col gap-4">
											<SectionStep step={1} state="current">
												Log in or sign up
											</SectionStep>
											<AuthFlow inline headers={authHeaders} showSteps={false} />
										</div>
									}
								>
									{(user) => (
										<>
											<SectionStep
												step={1}
												state="completed"
												action={
													<TextLink
														href="/auth/logout"
														onClick={(e) => {
															e.preventDefault();
															handleLogout();
														}}
													>
														Log out
													</TextLink>
												}
											>
												Account information
											</SectionStep>

											<ul class="flex flex-col gap-1">
												<li>
													{user().me.firstName} {user().me.lastName}
												</li>
												<li>Email address: {user().me.email}</li>
												<li>Troon Rewards #{user().me.troonRewardsId}</li>
											</ul>
										</>
									)}
								</Show>
							</Section>

							<Section appearance="contained">
								<SectionStep
									step={2}
									state={verification.state === 'VERIFIED' ? 'completed' : user() ? 'current' : 'waiting'}
								>
									Verify your Visa Card
								</SectionStep>
								<Show when={user() && verification.state !== 'VERIFIED'}>
									<p>Enter your card number to verify and authenticate your elegibility for Troon Rewards benefits.</p>
									<ErrorBoundary
										fallback={(error) => {
											return (
												<Show when={error.statusCode !== 401}>
													<MessageBar>{error.displayMessage ?? error.message ?? error.toString()}</MessageBar>
												</Show>
											);
										}}
									>
										<Suspense fallback={<ActivityIndicator />}>
											<Show when={submission.result?.token} fallback={<ActivityIndicator />}>
												{(token) => (
													<iframe
														tabIndex={-1}
														class="w-full"
														src={`${iframeUrls[getConfigValue('ENVIRONMENT') ?? 'production']}?Token=${token()}`}
													/>
												)}
											</Show>
										</Suspense>
									</ErrorBoundary>
								</Show>
							</Section>

							<Section appearance="contained">
								<SectionStep step={3} state={verification.state === 'VERIFIED' ? 'current' : 'waiting'}>
									Your Troon Rewards® Benefits
								</SectionStep>
							</Section>
						</GridSeven>

						<GridFive>{props.children}</GridFive>
					</Grid>
				</Page>
			</Container>
		</div>
	);
}

export const route = { info: { nav: { appearance: 'extra-minimal' } } } satisfies RouteDefinition;

const postGetVisaIframeToken = clientAction('POST', '/visa/token', {});

const iframeUrls: Record<Required<Window['__TROON_CONFIG__']>['ENVIRONMENT'], string> = {
	development: 'https://vcescert.visammg.com/VCESIFrame/',
	// development: 'https://vcescert.visammg.com/VCESIFrameSbx/',
	staging: 'https://vcescert.visammg.com/VCESIFrame/',
	production: 'https://www.visammg.com/VCESIFrame/',
};
